.Footer {
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 5px;
  background-color: #c4a68e;
  color: white;
}

.Footer a:link,
a:visited {
  background-color: #c4a68e;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 5px;
}

.ebu-image {
  width: 100px;
  height: 50px;
}
