.container {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 200px;
  background-color: #c4a68e;
  position: absolute;
  width: 100%;
}

.menu-icon {
  display: none;
  z-index: 99;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 50px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #ffffff;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #c4a68e;
    width: 0px;
    height: calc(100vh - 0px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
