html {
  overflow-y: scroll;
  overflow-x: hidden;
  align-items:flex-start;
}

.home {
  padding-top: 250px;
  padding-bottom: 150px;
  align-self: flex-start;
  margin: auto;
  width: 50%;
}

.gallary {
  padding-top: 200px;
  justify-content: center;
  text-align: center;
  padding-bottom: 20px;
}

.image-gallery-content {
  justify-content: center;
  width: 40%;
  height: auto;
  align-items: center;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}


.about {
  padding-top: 250px;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}


.about-board {
  padding-top: 250px;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.about-constitution {
  padding-top: 250px;
  align-items: center;
  text-align: center;
  padding-bottom: 100px;
}

.about-membership {
  padding-top: 250px;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.projects {
  padding-top: 250px;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.projects-history {
  padding-top: 250px;
  align-items: center;
  text-align: center;
  padding-bottom: 10px;
}


.ag-theme-quartz {
  /* Changes the color of the grid text */
    --ag-foreground-color: rgb(14, 68, 145);
    /* Changes the color of the grid background */
    --ag-background-color: rgb(241, 247, 255);
    /* Changes the header color of the top row */
    --ag-header-background-color: rgb(228, 237, 250);
    /* Changes the hover color of the row*/
    --ag-row-hover-color: rgb(216, 226, 255);
}

.projects-current {
  padding-top: 250px;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.projects-year-plan {
  padding-top: 250px;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.contact {
  padding-top: 250px;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
}
.contact p, h4{
  font-size: 18px;
}

.donation {
  padding-top: 250px;
  align-items: center;
  text-align: center;
  padding-bottom: 100px;
}
.donation p, h4{
  font-size: 18px;
}

.cards {
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  margin: 20px;
}
.card {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7dfd9;
  border-radius: 40%;
  padding: 0px 10px 10px 10px;
  margin: 30px;
  box-shadow: 0 4px 6px #110c08;
  background-color: #e3a372;
}

.card:nth-child(odd) {
  align-self: flex-end;
  background-color: #3fcfb5;
}

.card-text {
  font-size: 18px;
  color: #1f1e17;
}

.datenschutz {
   padding-top: 250px;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
}
.impressum {
  padding-top: 250px;
  align-items: center;
  text-align: center;
  padding-bottom: 50px;
}